import { Controller } from "@hotwired/stimulus";
import Sortable from "sortablejs";

export default class extends Controller {
  static values = {
    locationRadius: Object,
  };

  static targets = [
    "tab",
    "tabs",
    "searchInput",
    "searchCategoryForm",
    "filtersForm",
    "applyFiltersButton",
    "toggleDiv",
    "closeChat",
    "chatDrawer",
    "chatSideDrawer",
    "vectorSearchInput",
  ];

  connect() {
    this.shouldSubmitByEnter = false;
    this.isSubmitting = false;
    this.timeout = null;
    jQuery("#ai_search_dropdown_filter").off("change");
    jQuery("#ai_search_dropdown_filter")
      .select2()
      .on("change", (e) => {
        e.preventDefault();
        e.currentTarget?.form?.requestSubmit();
      });

    document.addEventListener("click", (event) => {
      const asideDrawer = document.querySelector(".aside-drawer");
      const drawerContent = document.querySelector(".aside-drawer > div");

      if (
        asideDrawer &&
        !asideDrawer.classList.contains("hidden") &&
        !drawerContent.contains(event.target) &&
        !event.target.matches(".opendrawer, .opendrawer *")
      ) {
        this.closeFilter();
      }
    });
  }

  submitAndActiveFilter(event) {
    const form = event.target.form;
    if (form) {
      form.requestSubmit();
    }
    const selector = event.target.dataset.selector;
    if (event.target.checked) {
      $(`#${event.target.dataset.activeKey}`).addClass("has-dot");
    } else {
      const totalChecked = this.getCheckedCount(selector);
      if (totalChecked == 0) {
        $(`#${event.target.dataset.activeKey}`).removeClass("has-dot");
      }
    }
  }

  getCheckedCount(className) {
    const checkboxes = document.getElementsByClassName(className);
    let checkedCount = 0;

    Array.from(checkboxes).forEach((checkbox) => {
      if (checkbox.checked) {
        checkedCount++;
      }
    });

    return checkedCount;
  }

  activateTab(event) {
    const clickedTab = event.currentTarget;
    this.tabTargets.forEach((tab) => {
      tab.classList.remove("active");
    });
    clickedTab.classList.add("active");
    const current_view = event.currentTarget.dataset.view;
    this.appendStateInURL(current_view);

    const form_id = event.currentTarget.dataset.formId;
    const form = document.getElementById(form_id);
    if (form) {
      form.querySelector('input[name="view"]').value = current_view;
      form.submit();
    }
  }

  appendStateInURL(state) {
    let currentUrl = new URL(window.location);
    currentUrl.searchParams.set("view", state);
    window.history.pushState({}, "", currentUrl);
  }

  activateTabs(event) {
    const clickedTab = event.currentTarget;
    this.tabsTargets.forEach((tab) => {
      tab.classList.remove("border-b-2");
      tab.classList.remove("border-[#3076FF]");
    });
    clickedTab.classList.add("border-b-2");
    clickedTab.classList.add("border-[#3076FF]");
  }

  debounceSearch(event) {
    const value = this.searchInputTarget.value;
    const search_button = document.getElementById("search-button");

    if (event.key === "Enter") {
      event.preventDefault();

      if (search_button.disabled) {
        return false;
      }

      if (this.shouldSubmitByEnter) {
        event.currentTarget.form.requestSubmit();
        return;
      }
    }

    this.isSubmitting = true;

    clearTimeout(this.timeout);

    search_button.disabled = true;
    if (value.trim() == "") {
      search_button.disabled = false;
      this.showSearchLoading(false);
      return false;
    }
    this.showSearchLoading(true);
    this.timeout = setTimeout(() => {
      this.searchCategories(value);
    }, 1000);
  }

  showSearchLoading(flag) {
    const loader = document.getElementById("search_filter_loader");
    if (flag) {
      loader && loader.classList.remove("hidden");
    } else {
      loader && loader.classList.add("hidden");
    }
  }

  debounceVectorSearch(event) {
    const value = this.vectorSearchInputTarget.value;

    if (event.key === "Enter") {
      event.preventDefault();
      clearTimeout(this.timeout);
      return false;
    }

    if (!this.vectorSearchInputTarget) return;
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.searchByVector(value);
    }, 400);
  }

  searchByVector(query) {
    const form = document.getElementById("vector_search_form");
    form.addEventListener("turbo:submit-end", () => {}, { once: true });
    form.requestSubmit();
  }

  searchCategories(query) {
    if (!this.hasSearchCategoryFormTarget) return;
    const search_categories_query = document.getElementById(
      "search_categories_query"
    );
    const search_button = document.getElementById("search-button");
    if (search_categories_query) {
      search_categories_query.value = query;
    }
    this.isSubmitting = true;
    const form = this.searchCategoryFormTarget;
    form.addEventListener(
      "turbo:submit-start",
      () => {
        if (search_button) {
          search_button.disabled = true;
        }
        this.shouldSubmitByEnter = false;
      },
      { once: true }
    );

    form.addEventListener(
      "turbo:submit-end",
      () => {
        if (search_button) {
          search_button.disabled = false;
        }
        this.showSearchLoading(false);
        this.shouldSubmitByEnter = true;
        this.isSubmitting = false;
        clearTimeout(this.timeout);
      },
      { once: true }
    );
    form.requestSubmit();
  }

  search(event) {
    const value = this.searchInputTarget.value;
    if (value != "") {
      event.currentTarget.form.requestSubmit();
    }
  }

  openFilter() {
    const asideDrawer = document.querySelector(".aside-drawer");
    var body = document.body;
    body.classList.add("overflow-hidden");
    if (asideDrawer.classList.contains("hidden")) {
      asideDrawer.classList.remove("hidden");
      asideDrawer.classList.add("flex");

      void asideDrawer.offsetWidth;

      asideDrawer.classList.remove("opacity-0");
      if (asideDrawer.children.length === 1) {
        asideDrawer.children[0].classList.add("!translate-x-0");
      }
    }

    if (asideDrawer) {
      if (!asideDrawer.firstElementChild.classList.contains("max-w-[500px]")) {
        const button_wrap = document.getElementsByClassName("button-wrap");
        for (let wrap of button_wrap) {
          wrap.removeAttribute("style");
        }

        const filterSort = document.getElementsByClassName("filter-sort");
        for (let sort of filterSort) {
          sort.removeAttribute("style");
        }

        const filterItems = document.getElementsByClassName("filter-items");
        for (let item of filterItems) {
          item.removeAttribute("style");
        }

        const openFilters = document.querySelector(".openfilters");
        if (openFilters) {
          openFilters.innerHTML = `Edit Filters <i class="anron-edit-3 text-[14px]"></i>`;
        }
      }
    }
  }

  showVideoask(event) {
    event.preventDefault();
  }

  closeFilter() {
    const asideDrawer = document.querySelector(".aside-drawer");
    var body = document.body;
    body.classList.remove("overflow-hidden");
    asideDrawer.classList.add("opacity-0");
    if (asideDrawer.children.length === 1) {
      asideDrawer.children[0].classList.remove("!translate-x-0");
      asideDrawer.children[0].classList.remove("max-w-[500px]");
    }

    setTimeout(() => {
      asideDrawer.classList.remove("flex");
      asideDrawer.classList.add("hidden");
    }, 300);
  }

  openAllFilters() {
    const asideDrawer = document.querySelector(".aside-drawer");
    if (asideDrawer) {
      [...asideDrawer.children].forEach((child) =>
        child.classList.toggle("max-w-[500px]")
      );
    }

    const filterItems = document.getElementsByClassName("filter-items");
    for (let item of filterItems) {
      item.style.display = item.style.display === "none" ? "flex" : "none";
    }

    const filterSort = document.getElementsByClassName("filter-sort");
    for (let sort of filterSort) {
      sort.style.display = sort.style.display === "flex" ? "none" : "flex";
    }

    const buttonWrap = document.getElementsByClassName("button-wrap");
    for (let wrap of buttonWrap) {
      wrap.style.display = wrap.style.display === "none" ? "flex" : "none";
    }

    const openFilters = document.querySelector(".openfilters");
    if (openFilters.innerHTML.includes("Edit Filters")) {
      openFilters.innerHTML = `Save Filters <i class="anron-check text-[14px]"></i>`;
    } else {
      openFilters.innerHTML = `Edit Filters <i class="anron-edit-3 text-[14px]"></i>`;
      document.getElementById("filters-form").requestSubmit();
    }

    const sortable_left = document.getElementById("example2-left");
    const sortable_right = document.getElementById("example2-right");
    this.initSortable(sortable_left);
    this.initSortable(sortable_right);
  }

  initSortable(element) {
    Sortable.create(element, {
      group: "shared",
      animation: 30,
      onStart: function (evt) {
        evt.item.classList.add("bg-white", "shadow-sm");
      },
      onEnd: function (evt) {
        evt.item.classList.remove("bg-white", "shadow-sm");

        const targetElement =
          evt?.item?.lastElementChild?.previousElementSibling;

        if (evt.to.id === "example2-left") {
          if (targetElement) {
            targetElement.value = true;
          }
        }

        if (evt.to.id === "example2-right") {
          if (targetElement) {
            targetElement.value = false;
          }
        }
      },
    });
  }

  disconnect() {
    const destroySelect2 = (selector) => {
      const element = $(this.element).find(selector);
      if (element.length && $.fn.select2 && element.data("select2")) {
        element.select2("destroy");
      }
    };

    destroySelect2("#ai_search_dropdown_filter");
  }

  applyFilters(event) {
    const show_results_count = document.getElementById("show_results_count");
    if (show_results_count) {
      show_results_count.value = false;
    }

    const should_update_filters = document.getElementById(
      "should_update_filters"
    );
    if (should_update_filters) {
      should_update_filters.value = true;
    }
    this.applyFiltersButtonTarget.disabled = true;
    this.applyFiltersButtonTarget.lastElementChild.classList.remove("hidden");
    const form = this.filtersFormTarget;
    const filters_query_search = form.querySelector("input[name='q']");
    const search_query = this.hasSearchInputTarget
      ? this.searchInputTarget.value
      : null;
    if (
      (search_query != "" || search_query != undefined) &&
      search_query != null
    ) {
      filters_query_search.value = search_query;
    }

    form.addEventListener(
      "turbo:submit-end",
      () => {
        if (form.querySelector("turbo-frame")) {
          this.applyFiltersButtonTarget.disabled = false;
          this.applyFiltersButtonTarget.lastElementChild.classList.add(
            "hidden"
          );
          this.closeFilter();
          filters_query_search.value = "";
        }
      },
      { once: true }
    );
    form.requestSubmit();
  }

  show() {
    if (this.toggleDivTarget.classList.contains("hidden")) {
      this.toggleDivTarget.classList.remove("hidden");
    }
  }

  hide() {
    if (!this.toggleDivTarget.classList.contains("hidden")) {
      this.toggleDivTarget.classList.add("hidden");
    }
  }

  toggleHistory(event) {
    event.preventDefault();
    const isSmallScreen = window.matchMedia("(max-width: 1024px)").matches;

    if (isSmallScreen) {
      this.chatSideDrawerTarget.classList.toggle("slide-out");
    }
  }

  closeHistory(event) {
    event.preventDefault();
    if (this.chatSideDrawerTarget.classList.contains("slide-out")) {
      this.chatSideDrawerTarget.classList.remove("slide-out");
    }
  }

  toggleSelectProfiles() {
    const customDropDown = document.getElementById("custom-dropdown");
    const profileCheckBoxes = document.querySelectorAll(".profile_checkbox");
    const itemsValue = customDropDown ? customDropDown.dataset.items : null;
    if (itemsValue !== "all") {
      profileCheckBoxes.forEach((checkbox) =>
        checkbox.classList.remove("hidden")
      );
    }
  }
}
