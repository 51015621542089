import { Controller } from "@hotwired/stimulus";
import tippy from "tippy.js";

export default class extends Controller {
  connect() {
    const locationsData = this.data.get("locations");
    const badge = this.data.get("globalBadge");
    const search_histroy = this.data.get("searchHistroy");
    if (locationsData) {
      this.setupTooltip(this.generateLocationsContent(locationsData));
    }
    if (badge) {
      this.setupTooltip(this.generateBadgeContent(badge));
    }
    if (search_histroy) {
      this.setupTooltip(this.generateSearchHistoryContent(search_histroy));
    }
  }

  setupTooltip(content) {
    tippy(this.element, {
      content: content,
      allowHTML: true,
      appendTo: () => document.body,
      arrow: true,
      placement: this.data.get("locations") ? "right" : "top",
      interactive: true,
    });
  }

  generateLocationsContent(locationsData) {
    const locations = JSON.parse(locationsData);
    return `
      <div class=" bg-white rounded-[10px]">
        <ul class="list-none inline-flex flex-wrap gap-[10px] m-0 shadow-shadow_two rounded-[10px] p-[12px]">
          ${locations
            .map(
              (location) =>
                `<li> <span class='experencise-tag'>${location.trim()}</span>  </li>`
            )
            .join("")}
        </ul>
      </div>
    `;
  }

  generateBadgeContent(badge) {
    return `
      <div class=" bg-white rounded-[10px]">
        <span class="experencise-tag tippydark">${badge}</span>
      </div>
    `;
  }
  generateSearchHistoryContent(badge) {
    return `<div class="rounded-[6px]"> <span class="tippydark max-w-max inline-block text-center leading-5">${badge}</span> </div>`;
  }
}
