import { Controller } from "@hotwired/stimulus";
export default class extends Controller {
  static targets = ["asideDrawer", "asideDrawerChildren", "form"];

  connect() {
    this.timeout = null;
    document.addEventListener("click", this.handleClickOutside.bind(this));
  }

  disconnect() {
    document.removeEventListener("click", this.handleClickOutside.bind(this));
  }

  openDrawer() {
    var body = document.body;
    body.classList.add("overflow-hidden");

    this.asideDrawerTarget.classList.remove("hidden");
    this.asideDrawerTarget.classList.add("flex", "opacity-0");

    void this.asideDrawerTarget.offsetWidth;

    this.asideDrawerTarget.classList.remove("opacity-0");
    this.asideDrawerChildrenTarget.classList.add("!translate-x-[0]");
  }

  searchByName(event) {
    clearTimeout(this.timeout);
    const value = event.currentTarget.value;
    if (value.trim() == "") {
      return false;
    }
    this.timeout = setTimeout(() => {
      const form = document.getElementById("company-filters-form");
      if (form) {
        form.requestSubmit();
      }
    }, 600);
  }

  submitDrawerForm(event) {
    this.closeDrawer();
    document.getElementById("show_results_count").value = false;
    document.getElementById("should_update_filters").value = true;
    event.currentTarget.form.requestSubmit();
  }

  closeDrawer() {
    this.asideDrawerChildrenTarget.classList.remove("!translate-x-[0]");
    this.asideDrawerTarget.classList.add("opacity-0");

    setTimeout(() => {
      this.asideDrawerTarget.classList.add("hidden");
      this.asideDrawerTarget.classList.remove("flex", "opacity-0");
      var body = document.body;
      body.classList.remove("overflow-hidden");
    }, 300);
  }

  handleClickOutside(event) {
    if (
      !this.asideDrawerChildrenTarget.contains(event.target) &&
      !event.target.closest(".opendrawer")
    ) {
      this.closeDrawer();
    }
  }
}
