import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["menu", "form"];

  connect() {
    this.closeMenuHandler = this.closeMenu.bind(this);
  }

  toggle(event) {
    event.stopPropagation();
    this.menuTarget.classList.toggle("hidden");

    if (!this.menuTarget.classList.contains("hidden")) {
      document.addEventListener("click", this.closeMenuHandler);
    } else {
      document.removeEventListener("click", this.closeMenuHandler);
    }
  }

  closeMenu(event) {
    if (!this.element.contains(event.target)) {
      this.hideMenu();
    }
  }

  hideMenu() {
    this.menuTarget.classList.add("hidden");
    document.removeEventListener("click", this.closeMenuHandler);
  }

  submitOnEnter(event) {
    if (event.key === "Enter") {
      this.formTarget.submit();
    }
  }

  disconnect() {
    document.removeEventListener("click", this.closeMenuHandler);
  }

  submitHistory() {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.formTarget.requestSubmit();
    }, 800);
  }
}
